'use client'

import dynamic from 'next/dynamic'
import { Box } from '@mui/material'
import { Spinorama, SpinoramaWrapper, SpinoramaItem, SpinoramaNext, SpinoramaPrev } from 'spinorama'
import { ArrowBackIosNewRounded as ArrowBackIosNewRoundedIcon, ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@mui/icons-material'

// Types
import type { CoursesMoleculeProps } from '@/types/components/molecules/cards/courses'

// Components
const CardCoursesAtom = dynamic(() => import('@/components/atoms/cards/courses'))

const CoursesMolecule = (props: CoursesMoleculeProps) => {
	const { items } = props
	return (
		<Spinorama interval={9500} ease="power4.inOut">
			<SpinoramaWrapper spacing={3} px={5} py={1}>
				{items.map((item, index) => (
					<SpinoramaItem key={index} size={4}>
						<CardCoursesAtom {...item} />
					</SpinoramaItem>
				))}
			</SpinoramaWrapper>

			<SpinoramaPrev layout={'center-left'} showOnHover>
				<Box
					width={38}
					height={38}
					display="flex"
					alignItems="center"
					justifyContent="center"
					bgcolor="text.secondary"
					borderRadius={1}
					sx={{ ':hover': { bgcolor: 'primary.dark' }, '& .MuiSvgIcon-root': { color: 'common.white' } }}
				>
					<ArrowBackIosNewRoundedIcon />
				</Box>
			</SpinoramaPrev>

			<SpinoramaNext layout={'center-right'} showOnHover>
				<Box
					width={38}
					height={38}
					display="flex"
					alignItems="center"
					justifyContent="center"
					bgcolor="text.secondary"
					borderRadius={1}
					sx={{ ':hover': { bgcolor: 'primary.dark' }, '& .MuiSvgIcon-root': { color: 'common.white' } }}
				>
					<ArrowForwardIosRoundedIcon />
				</Box>
			</SpinoramaNext>
		</Spinorama>
	)
}

export default CoursesMolecule
